import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { downloadVCard } from "utilities/vCardGenerator";

import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Link from "@material-ui/core/Link";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import WorkIcon from "@material-ui/icons/Work";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LanguageIcon from "@material-ui/icons/Language";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import {
  CompanyFacebookAccUrl,
  CompanyLinkedInAccUrl,
  CompanyInstagramAccUrl
} from "utilities/constants";

import logo from "newLogo.png";
import BlankPhoto from "blankPhoto.png";

const useStyles = makeStyles(theme => ({
  paper: {
    color: theme.palette.primary.main
  },
  loader: {
    textAlign: "center",
    margin: "50%"
  },
  logoContainer: {
    maxHeight: "2.8em",
    textAlign: "left",
    position: "relative",
    paddingTop: "1em",
    paddingLeft: "1em"
  },
  logo: {
    maxHeight: "3.8em"
  },
  photoContainer: {
    textAlign: "center",
    padding: "1.5em",
    paddingTop: "2.5em"
  },
  userPhoto: {
    maxHeight: "300px",
    borderRadius: "50%"
  },
  nameContainer: {
    textAlign: "center",
    marginBottom: "1.8em"
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  socialIcons: {
    textAlign: "center",
    paddingTop: "1em",
    paddingBottom: ".5em"
  }
}));

const getAddress = user => {
  return `${user.streetAddress ? user.streetAddress : ""}, 
    ${user.city ? user.city : ""}, 
    ${user.country ? user.country : ""}`;
};

export default function UserProfile() {
  const { username } = useParams();
  const classes = useStyles();
  const [, setErrors] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [photoLoaded, setPhotoLoaded] = useState(false);
  const [photoBase64, setPhotoBase64] = useState("");

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/userprofile/info/${username}`
      );
      res
        .json()
        .then(res => setUserInfo(res))
        .catch(() => setErrors(true));
    }
    fetchData();
  }, [username]);

  useEffect(() => {
    async function downloadAndConvertImg() {
      let imageUrl = `${process.env.REACT_APP_API_URL}/userprofile/photo/${username}`;
      return await fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }))
      .then(imageData => setPhotoBase64(imageData));
    }
    downloadAndConvertImg();
  }, [username]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`TBS Contacts - ${userInfo.displayName}`}</title>
      </Helmet>
      {!(userInfo.displayName) ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      <Fade in={(userInfo.displayName) ? true : false}>
        <Paper className={classes.paper}>
          <div>
            <div className={classes.logoContainer}>
              <img
                className={classes.logo}
                src={logo}
                alt="Telelink Business Services Logo"
              />
            </div>

            <div className={classes.photoContainer}>
              {!photoLoaded && <img className={classes.userPhoto} src={BlankPhoto} />}
              <img
                className={classes.userPhoto} alt=""
                src={`${process.env.REACT_APP_API_URL}/userprofile/photo/${username}`}
                onLoad={() => setPhotoLoaded(true)}
              />
            </div>

            <div className={classes.nameContainer}>
              <Typography
                variant="h3"
                component="h1"
                style={{ fontWeight: 900 }}
              >
                {userInfo.displayName}
              </Typography>
            </div>

            <div>
              <ButtonGroup fullWidth color="primary">
                <Button
                  size="large"
                  component={Link}
                  href={`tel:${userInfo.mobilePhone}`}
                >
                  <PhoneIcon className={classes.leftIcon} />
                  Call
                </Button>
                <Button
                  size="large"
                  component={Link}
                  href={`mailto:${userInfo.email}?subject=Hello, ${userInfo.displayName}`}
                >
                  <EmailIcon className={classes.leftIcon} />
                  Email
                </Button>
                <Button
                  size="large"
                  component={Link}
                  onClick={() => downloadVCard(username.trim(), userInfo, photoBase64)}
                  target="_blank"
                >
                  <PersonAddIcon className={classes.leftIcon} />
                  Contact
                </Button>
              </ButtonGroup>
            </div>
          </div>

          <Container>
            <List component="nav">
              <ListItem>
                <ListItemIcon>
                  <WorkIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={userInfo.jobTitle}
                  secondary={userInfo.department}
                />
              </ListItem>
              <Divider />

              <ListItem
                button
                component={Link}
                href={`mailto:${userInfo.email}?subject=Hello, ${userInfo.displayName}`}
              >
                <ListItemIcon>
                  <EmailIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={userInfo.email} secondary="Email" />
              </ListItem>
              <Divider />

              <ListItem
                button
                component={Link}
                href={`tel:${userInfo.mobilePhone}`}
              >
                <ListItemIcon>
                  <PhoneIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={userInfo.mobilePhone}
                  secondary="Mobile"
                />
              </ListItem>
              <Divider />

              <ListItem
                button
                component={Link}
                href={`https://www.google.com.sa/maps/search/${getAddress(
                  userInfo
                )}`}
                target="_blank"
              >
                <ListItemIcon>
                  <LocationCityIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={getAddress(userInfo)}
                  secondary="Location"
                />
              </ListItem>
              <Divider />

              <ListItem
                button
                component={Link}
                href="https://tbs.tech"
                target="_blank"
              >
                <ListItemIcon>
                  <LanguageIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="https://tbs.tech"
                  secondary="Company website"
                />
              </ListItem>
            </List>
          </Container>

          <Divider />

          <Container className={classes.socialIcons}>
            <Link href={CompanyLinkedInAccUrl}>
              <LinkedInIcon fontSize="large" />
            </Link>
            <Link href={CompanyFacebookAccUrl}>
              <FacebookIcon fontSize="large" />
            </Link>
            <Link href={CompanyInstagramAccUrl}>
              <InstagramIcon fontSize="large" />
            </Link>
          </Container>
        </Paper>
      </Fade>
    </React.Fragment>
  );
}
