import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import UserProfile from "components/UserProfile/UserProfile";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Barlow, sans-serif"
  },
  palette: {
    primary: {
      main: "#0269e6"
    }
  }
});

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "3em",
    marginBottom: "2.5em"
  }
}));

const UserVCard = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <ThemeProvider theme={theme}>
        <UserProfile />
      </ThemeProvider>
    </Container>
  );
};

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path="/:username" component={UserVCard} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
