export const UserInfoEmptyState = {
  displayName: "Employee Name",
  givenName: "Employee",
  surname: "Name",
  jobTitle: "Job Title",
  department: "Department",
  email: "tbs.employee@tbs.tech",
  mobilePhone: "+359 888 888 888",
  companyName: "Telelink Business Services",
  country: "Bulgaria",
  city: "Sofia",
  streetAddress: "2 Donka Ushlinova str.",
  officeLocation: "Garitage Building 1, Floor 4",
  postalCode: "1756"
};

export const CompanyWebsite = "https://tbs.tech";
export const CompanyContactsPage = "https://contacts.tbs.tech";

export const CompanyFacebookAccUrl =
  "https://www.facebook.com/TelelinkBusinessServices/";
export const CompanyLinkedInAccUrl =
  "https://www.linkedin.com/company/telelink-business-services/";
export const CompanyInstagramAccUrl =
  "https://www.instagram.com/telelinkbusinessservices/";
