import vCardsJS from "vcards-js";

import {
  CompanyContactsPage,
  CompanyFacebookAccUrl,
  CompanyLinkedInAccUrl,
  CompanyInstagramAccUrl
} from "utilities/constants";

export const generateVCard = (userInfo, imageData, username) => {
  let vCard = vCardsJS();
  vCard.firstName = userInfo.givenName;
  vCard.lastName = userInfo.surname;
  vCard.organization = userInfo.companyName;
  vCard.email = userInfo.email;
  vCard.workPhone = userInfo.mobilePhone;
  vCard.title = userInfo.jobTitle;

  let base64Start = imageData.indexOf("9j");
  vCard.photo.embedFromString(imageData.substring(base64Start - 1), "image/jpeg");

  vCard.url = "https://tbs.tech";
  vCard.source = `${CompanyContactsPage}/${username}`;

  vCard.workAddress.street = userInfo.streetAddress;
  vCard.workAddress.city = userInfo.city;
  vCard.workAddress.countryRegion = userInfo.country;
  vCard.workAddress.postalCode = userInfo.postalCode;

  vCard.socialUrls["facebook"] = CompanyFacebookAccUrl;
  vCard.socialUrls["linkedIn"] = CompanyLinkedInAccUrl;
  vCard.socialUrls["instagram"] = CompanyInstagramAccUrl;

  return vCard.getFormattedString();
};

export const downloadVCard = (username, userInfo, imageData) => {
  let userVCardInfo = generateVCard(userInfo, imageData, username);
  const downloadEl = document.createElement("a");
  const file = new Blob([userVCardInfo], { type: "text/vcard" });
  downloadEl.href = URL.createObjectURL(file);
  downloadEl.download = `${username}.vcf`;
  document.body.appendChild(downloadEl);
  downloadEl.click();
  document.body.removeChild(downloadEl);
};
